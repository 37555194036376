import { parse as JSURLParse, stringify as JSURLstringify } from "jsurl2";

const projectIdAlias = "P-ID";

function toUrlKey(key: string) {
  const projectId = window.location.pathname.match(/^\/projects\/([^/]+)/)?.[1] ?? null;

  key = key.replace(`${SHAREABLE_PREFIX}~`, "");
  if (projectId !== null) key = key.replace(projectId, projectIdAlias);
  return key;
}

function fromUrlKey(key: string) {
  const projectId = window.location.pathname.match(/^\/projects\/([^/]+)/)?.[1] ?? null;

  key = `${SHAREABLE_PREFIX}~${key}`;
  if (projectId !== null) key = key.replace(projectIdAlias, projectId);
  return key;
}

const toSearchString = (searchParams: URLSearchParams) =>
  searchParams.size
    ? `?${searchParams
        .toString()
        .replaceAll("%28", "(")
        .replaceAll("%29", ")")
        .replaceAll("%7E", "~")
        .replaceAll("%21", "!")}`
    : "";
const searchParamsToUrl = (searchParams: URLSearchParams, withHost = true) => {
  const { protocol, host, pathname, hash } = window.location;
  const prefix = withHost ? `${protocol}//${host}` : "";
  return `${prefix}${pathname}${toSearchString(searchParams)}${hash}`;
};

const SHAREABLE_QUERY_KEY_1 = "shared";
const SHAREABLE_QUERY_KEY_2 = "set-options";
export const SHAREABLE_PREFIX = "shareable";
export const SHARED_URL_APPLIED = "shared-url-applied";
export const shareableKeys = new Map<string, number>();

/**
 * @deprecated for reference only
 */
// @ts-expect-error 6133
function buildShareableURL1() {
  const searchParams = new URLSearchParams(window.location.search);
  const keys = Array.from(shareableKeys.keys());
  const shareableHash = keys.reduce<{ [key: string]: null | string }>((agg, key) => {
    agg[key] = window.sessionStorage.getItem(key);
    return agg;
  }, {});

  if (Object.keys(shareableHash).length)
    searchParams.set(SHAREABLE_QUERY_KEY_1, JSON.stringify(shareableHash));
  return searchParamsToUrl(searchParams, true);
}

export function buildShareableURL2() {
  const searchParams = new URLSearchParams(window.location.search);
  const keys = Array.from(shareableKeys.keys());
  const shareableHash = keys.reduce<{ [key: string]: null | string }>((agg, key) => {
    const value = window.sessionStorage.getItem(key);
    agg[toUrlKey(key)] = value === null ? null : JSON.parse(value);
    return agg;
  }, {});

  if (Object.keys(shareableHash).length)
    searchParams.set(SHAREABLE_QUERY_KEY_2, JSURLstringify(shareableHash));

  return searchParamsToUrl(searchParams, true);
}

export function applyShareableURL(): string | null {
  const searchParams = new URLSearchParams(window.location.search);
  const shareable1 = searchParams.get(SHAREABLE_QUERY_KEY_1);
  const shareable2 = searchParams.get(SHAREABLE_QUERY_KEY_2);
  if (shareable1 === null && shareable2 === null) return null;

  if (shareable1) applyShareable1(shareable1);
  if (shareable2) applyShareable2(shareable2);

  searchParams.delete(SHAREABLE_QUERY_KEY_1);
  searchParams.delete(SHAREABLE_QUERY_KEY_2);
  searchParams.set(SHARED_URL_APPLIED, "true");

  return searchParamsToUrl(searchParams, false);
}

function applyShareable1(shareableHashValue: string) {
  if (shareableHashValue === null) return;
  const shareableHash = JSON.parse(shareableHashValue) as {
    [k: string]: string | null;
  };
  for (const [key, value] of Object.entries(shareableHash)) {
    if (!key.startsWith(SHAREABLE_PREFIX)) {
      console.debug(`${key} is not shareable`);
      continue;
    }
    if (value === null) {
      window.sessionStorage.removeItem(key);
    } else {
      window.sessionStorage.setItem(key, value);
    }
  }
}

function applyShareable2(shareableHashValue: string) {
  if (shareableHashValue === null) return;
  const shareableHash = JSURLParse(shareableHashValue) as {
    [k: string]: string | null;
  };
  for (const [key, value] of Object.entries(shareableHash)) {
    if (value === null) {
      window.sessionStorage.removeItem(fromUrlKey(key));
    } else {
      window.sessionStorage.setItem(fromUrlKey(key), JSON.stringify(value));
    }
  }
}

const urlRegExp = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/,
);
export function validateUrl(url: string): boolean {
  return url.startsWith("https://") && urlRegExp.test(url);
}
